import { Auth } from "aws-amplify";
import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "@mui/material/styles";

import Sidebar from "./components/sidebar";
import Content from "./components/content";
import LoginModal from "./components/login/loginmodal";

import "./App.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#bb86fc"
    },
    secondary: { main: "#bb86fc" },
    grey: { main: "#bb86fc" },
    contrastText: "#fff"
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        position: "relative",
        "& $notchedOutline": {
          borderColor: "#bb86fc"
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#bb86fc",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "#bb86fc"
          }
        },
        "&$focused $notchedOutline": {
          borderColor: "#bb86fc",
          borderWidth: 1
        }
      }
    },
    MuiFormLabel: {
      root: {
        // "&$focused": {
        color: "#bb86fc"
        // }
      }
    }
  }
});

class App extends Component {
  constructor(props) {
    super(props);
    this.login_handler = this.login_handler.bind(this);
    this.set_user = this.set_user.bind(this);
    this.logout = this.logout.bind(this);

    this.state = {
      loginVisible: false,
      user: null,
    };
  }

  componentDidMount() {
    this.update_user_status();
  }

  update_user_status() {
    Auth.currentAuthenticatedUser()
      .then((user) => this.setState({ user: user }))
      .catch((_) => this.setState({ user: null }));
  }

  set_user(user) {
    this.setState({ user: user });
  }

  login_handler() {
    this.setState({ loginVisible: !this.state.loginVisible });
  }

  logout() {
    Auth.signOut()
      .then((_) => this.setState({ user: null }))
      .catch((_) => {
        console.log("Failed to log out");
        this.update_user_status();
      });
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <div id="App">
          <LoginModal
            openState={this.state.loginVisible}
            login_handler={this.login_handler}
            set_user={this.set_user}
          />
          <Router>
            <Sidebar
              current_user={this.state.user}
              login_handler={this.login_handler}
              logout={this.logout}
            />
            <Content current_user={this.state.user} />
          </Router>
        </div>
      </ThemeProvider>
    );
  }
}

export default App;
