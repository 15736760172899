import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarFooter, SidebarContent, SubMenu} from "react-pro-sidebar";
import { FaList, FaMoneyBillWave } from "react-icons/fa";
import { FiHome, FiLogIn, FiLogOut, FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";
import { RiDownloadCloud2Line } from "react-icons/ri";
import { GiReceiveMoney } from "react-icons/gi";
import { BiCog } from "react-icons/bi";
 
import "react-pro-sidebar/dist/css/styles.css";
import "../css/sidebar.css";
import logo from "../assets/profile.png"

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.state = {
      menuCollapse: false,
      modalIsOpen: false
    }
  }

  toggleMenu() {
    this.setState({menuCollapse: !this.state.menuCollapse})
  }

  render() {
    return (
        <div id="sidebar">
          <ProSidebar collapsed={this.state.menuCollapse}>
            <SidebarHeader>
              <div className="closemenu" onClick={this.toggleMenu}>
                  {this.state.menuCollapse ? (
                  <FiArrowRightCircle/>
                  ) : (
                  <FiArrowLeftCircle/>
                  )}
              </div>
            <div className="logotext">
              <img className="smalllogo" src={logo} alt="Logo" /> 
                {this.state.menuCollapse ? "" : <p className="logo">Piggypi </p> } 
              </div>
            </SidebarHeader>
            
            <SidebarHeader>
            {this.props.current_user ? (
              <div> 
                {!this.state.menuCollapse &&
                  <div className="login"> 
                    <div> Currently logged in as </div>
                    <div className="user"> {this.props.current_user.attributes.name}</div>
                  </div>
                }
              </div>
              
            ) : (
              <Menu iconShape="square">
                <MenuItem icon={<FiLogIn />} onClick={this.props.login_handler}>Login</MenuItem>
              </Menu>
            )}
            </SidebarHeader>

            <SidebarContent>
              <Menu iconShape="square">
                <MenuItem icon={<FiHome />}><NavLink to="/" />Home</MenuItem>
                <SubMenu title="Services" icon={<FaList />}>
                  <MenuItem icon={<RiDownloadCloud2Line />}><NavLink to="/cloud" />cloud</MenuItem>
                  <MenuItem icon={<GiReceiveMoney />}><NavLink to="/invest" />invest</MenuItem>
                  <MenuItem icon={<FaMoneyBillWave />}><NavLink to="/funds" />funds</MenuItem> 
                </SubMenu>
              </Menu>
            </SidebarContent>
            <SidebarFooter>
              <Menu iconShape="square">
                <MenuItem icon={<BiCog />}><NavLink to="/settings" />Settings</MenuItem> 
                {this.props.current_user && <MenuItem icon={<FiLogOut />} onClick={this.props.logout}>Logout</MenuItem>}
              </Menu>
            </SidebarFooter>
          </ProSidebar>
        </div>
    );
  };
}

export default withRouter(Sidebar);