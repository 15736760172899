import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Modal } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { styled, withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useInput } from "../../utils/forms";
import { Toast } from "../../utils/notifications";
import logo from "../../assets/profile.png";
import "../../css/login.css";

const font_styles = {
  input: {
    color: "#FFF"
  }
};

const Field = styled(TextField)({
  margin: "5px 0",
  color: "#fff",
});

const PButton = styled(Button)({
  margin: "20px 0"
});

const Login = ({ close, set_user, to_signup, to_password_reset, input}) => {
  const [loading, setLoading] = React.useState(false);
  const { value: email, bind: bindEmail } = useInput("");
  const { value: password, bind: bindPassword } = useInput("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    Auth.signIn(email, password)
      .then((user) => {
        set_user(user);
        Toast("Success!!", "Login Successfully", "success");
        close();
        setLoading(false);
      })
      .catch((err) => {
        Toast("Error!!", err.message, "danger");
        setLoading(false);
      });
  };

  return (
    <div className="modal_container">
      <img className="logo" src={logo} alt="Logo" />
      <form
        className="form"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        onSubmit={handleSubmit}
      >
        <Field label="Email" {...bindEmail} type="email" InputProps= {{className: input}}/>
        <Field label="Password" type="password" {...bindPassword} InputProps= {{className: input}}/>
        <button
          className="link-button"
          onClick={to_password_reset}
          style = {{
            marginRight: "auto",
            fontSize: 10
          }}
        >
          Forgot Password?
        </button>
        <PButton
          color="primary"
          variant="contained"
          size="large"
          type="submit"
          disabled={loading}
        >
          {loading && (
            <CircularProgress size={20} style={{ marginRight: 20 }} />
          )}
          Login
        </PButton>
      </form>
      <div className="footer" style={{fontSize: 10}}>
        Not a member yet?{" "}
        <button
          className="link-button"
          onClick={to_signup}
          style= {{
            fontSize: 10
          }}>
          Sign up
        </button>
      </div>
    </div>
  );
};

const Signup = ({ to_login, to_verify, input }) => {
  const [loading, setLoading] = React.useState(false);

  const { value: name, bind: bindName } = useInput("");
  const { value: email, bind: bindEmail } = useInput("");
  const { value: password, bind: bindPassword } = useInput("");
  const { value: confirmPassword, bind: bindConfirmPassword } = useInput("");

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (password !== confirmPassword) {
      Toast(
        "Error!!",
        "Password and Confirm Password should be same",
        "danger"
      );
      setLoading(false);
      return;
    }
    try {
      await Auth.signUp({
        username: email,
        password: confirmPassword,
        attributes: {
          email,
          name,
        },
      });
      Toast("Success!!", "Sign up successful", "success");
      to_login();
      setLoading(false);
    } catch (error) {
      console.error(error);
      Toast("Error!!", error.message, "danger");
      setLoading(false);
    }
  };

  return (
    <div className="modal_container">
      <img className="logo" src={logo} alt="Logo" />
      <form
        className="form"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        onSubmit={handleSignUp}
      >
        <Field label="Name" {...bindName} InputProps= {{className: input}} />
        <Field label="Email" {...bindEmail} type="email" InputProps= {{className: input}}/>
        <Field label="Password" type="password" {...bindPassword} InputProps= {{className: input}}/>
        <Field
          label="Confirm Password"
          type="password"
          {...bindConfirmPassword}
          InputProps= {{className: input}}
        />
        <PButton
          color="primary"
          variant="contained"
          size="large"
          type="submit"
          disabled={loading}
        >
          {loading && (
            <CircularProgress size={20} style={{ marginRight: 20 }} />
          )}
          Register
        </PButton>
      </form>
      <div className="footer">
        Already have an account?{" "}
        <button
          className="link-button"
          onClick={to_login}>
          Login
        </button>
      </div>
      <div className="footer">
        Lost your confirmation email?{" "}
        <button
          className="link-button"
          onClick={to_verify}>
          Resend
        </button>
      </div>
    </div>
  );
};

const Verify = ({ to_login, input }) => {
  const [loading, setLoading] = React.useState(false);
  const { value: email, bind: bindEmail } = useInput("");

  const handleVerify = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await Auth.resendSignUp(email);
      Toast("Success!!", "Verification link successfully sent", "success");
      to_login();
      setLoading(false);
    } catch (error) {
      console.error(error);
      Toast("Error!!", error.message, "danger");
      setLoading(false);
    }
  };

  return (
    <div className="modal_container">
      <img className="logo" src={logo} alt="Logo" />
      <form
        className="form"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        onSubmit={handleVerify}
      >
        <Field label="Email" {...bindEmail} type="email" InputProps= {{className: input}}/>
        <PButton
          color="primary"
          variant="contained"
          size="large"
          type="submit"
          disabled={loading}
        >
          {loading && (
            <CircularProgress size={20} style={{ marginRight: 20 }} />
          )}
          Resend Verification Link
        </PButton>
      </form>
    </div>
  );
};

const PasswordReset = ({ to_password_reset_submit, input}) => {
  const [loading, setLoading] = React.useState(false);
  const { value: email, bind: bindEmail } = useInput("");

  const handleReset = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await Auth.forgotPassword(email);
      Toast("Success!!", "Password Reset", "success");
      to_password_reset_submit();
      setLoading(false);
    } catch (error) {
      console.error(error);
      Toast("Error!!", error.message, "danger");
      setLoading(false);
    }
  };

  return (
    <div className="modal_container">
      <img className="logo" src={logo} alt="Logo" />
      <form
        className="form"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        onSubmit={handleReset}
      >
        <Field label="Email" {...bindEmail} type="email" InputProps= {{className: input}} />
        <PButton
          color="primary"
          variant="contained"
          size="large"
          type="submit"
          disabled={loading}
        >
          {loading && (
            <CircularProgress size={20} style={{ marginRight: 20 }} />
          )}
          Request Password Reset
        </PButton>
      </form>
    </div>
  );
};

const PasswordResetSubmit = ({ to_login, input}) => {
  const [loading, setLoading] = React.useState(false);
  const { value: email, bind: bindEmail } = useInput("");
  const { value: code, bind: bindCode } = useInput("");
  const { value: password, bind: bindPassword } = useInput("");
  const { value: confirmPassword, bind: bindConfirmPassword } = useInput("");

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (password !== confirmPassword) {
      Toast(
        "Error!!",
        "Password and Confirm Password should be same",
        "danger"
      );
      setLoading(false);
      return;
    }
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      Toast("Success!!", "Password Reset", "success");
      to_login();
      setLoading(false);
    } catch (error) {
      console.error(error);
      Toast("Error!!", error.message, "danger");
      setLoading(false);
    }
  };

  return (
    <div className="modal_container">
      <img className="logo" src={logo} alt="Logo" />
      <form
        className="form"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        onSubmit={handlePasswordReset}
      >
        <Field label="Email" {...bindEmail} type="email" InputProps= {{className: input}}/>
        <Field label="Code" {...bindCode} InputProps= {{className: input}}/>
        <Field label="Password" type="password" {...bindPassword} InputProps= {{className: input}}/>
        <Field
          label="Confirm Password"
          type="password"
          {...bindConfirmPassword}
          InputProps= {{className: input}}
        />
        <PButton
          color="primary"
          variant="contained"
          size="large"
          type="submit"
          disabled={loading}
        >
          {loading && (
            <CircularProgress size={20} style={{ marginRight: 20 }} />
          )}
          Complete Password Reset
        </PButton>
      </form>
    </div>
  );
};

class LoginModal extends Component {
  constructor(props) {
    super(props);
    this.close_modal = this.close_modal.bind(this);
    this.to_login = this.to_login.bind(this);
    this.to_signup = this.to_signup.bind(this);
    this.to_verify = this.to_verify.bind(this);
    this.to_password_reset = this.to_password_reset.bind(this);
    this.to_password_reset_submit = this.to_password_reset_submit.bind(this);
    this.state = {
      current_tab: "login", //["login", "signup", "verify", "password_reset", "password_reset_submit"]
    };
  }

  close_modal() {
    this.props.login_handler();
    this.to_login();
  }

  to_login() {
    this.setState({ current_tab: "login" });
  }
  to_signup() {
    this.setState({ current_tab: "signup" });
  }

  to_verify() {
    this.setState({ current_tab: "verify" });
  }

  to_password_reset() {
    this.setState({ current_tab: "password_reset" });
  }

  to_password_reset_submit() {
    this.setState({ current_tab: "password_reset_submit" });
  }

  renderTab(param) {
    const { classes } = this.props;
    switch (param) {
      case "signup":
        return <Signup to_login={this.to_login} to_verify={this.to_verify} input={classes.input}/>;
      case "verify":
        return <Verify to_login={this.to_login} input={classes.input}/>;
      case "password_reset":
        return (
          <PasswordReset
            to_password_reset_submit={this.to_password_reset_submit}
            input={classes.input}
          />
        );
      case "password_reset_submit":
        return <PasswordResetSubmit to_login={this.to_login} input={classes.input}/>;
      default: // login
        return (
          <Login
            close={this.close_modal}
            set_user={this.props.set_user}
            to_signup={this.to_signup}
            to_password_reset={this.to_password_reset}
            input={classes.input}
          />
        );
    }
  }

  render() {
    return (
        <Modal className="login-modal" show={this.props.openState} onHide={this.close_modal}>
          {this.renderTab(this.state.current_tab)}
        </Modal>
    );
  }
}

export default withStyles(font_styles)(LoginModal);
