import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import "../css/content.css";

class Content extends Component {
  render() {
    return (
        <div id="content">
        <Switch>
          <Route exact path="/">
            <div> 
              Hi {this.props.current_user == null ? "" : this.props.current_user.attributes.name}
            </div>
          </Route>
          <Route path="/invest">
            <h1> Invest </h1>
          </Route>
        </Switch>
        </div>
    );
  }
}

export default Content;
